import React from 'react'
import HeaderOne from '../components/header/HeaderOne'
import Banner from '../components/headerone/Banner'

function IndexOne() {
  return (
    <>
        <HeaderOne/>
            <main>
                <Banner/>
                {/* <AreaBg/> */}
            </main>
       {/* <Footer/> */}
    </>
  )
}

export default IndexOne